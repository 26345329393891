import { Container, Typography } from "@material-ui/core";
import * as React from "react";
import useStyles from "./Values.styles";

const Values = () => {
  const classes = useStyles();

  return (
    <Container style={{ padding: "32px 8px 64px 8px" }}>
      <Typography variant="h4" style={{ color: "#353b48", marginBottom: 32 }}>
        Our Values
      </Typography>
      <div>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            GOD-CENTRED
          </span>{" "}
          seeing the{" "}
          <span style={{ fontWeight: 500 }}>
            Father’s love as foundational and Jesus as pre-eminent.
          </span>
          <br />
          Expressed through prayer and worship.{" "}
          <a
            href="https://www.bible.com/bible/116/ISA.64.4.NLT"
            target="_blank"
          >
            Isaiah 64:4
          </a>
          ,{" "}
          <a
            href="https://www.bible.com/bible/116/COL.1.18.NLT"
            target="_blank"
          >
            Colossians 1:18
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            BIBLE-BASED
          </span>{" "}
          holding the{" "}
          <span style={{ fontWeight: 500 }}>
            Bible as the absolute and final authority.
          </span>
          <br />
          Expressed through Word study and application.{" "}
          <a
            href="https://www.bible.com/bible/116/2TI.3.16.NLT"
            target="_blank"
          >
            2 Timothy 3:16
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            SPIRIT-LED
          </span>{" "}
          desiring and obeying the{" "}
          <span style={{ fontWeight: 500 }}>leading of the Holy Spirit.</span>
          <br />
          Expressed through a relationship with and obedience to the Holy
          Spirit.{" "}
          <a
            href="https://www.bible.com/bible/116/ROM.8.14.NLT"
            target="_blank"
          >
            Romans 8:14
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            PEOPLE OF INTEGRITY
          </span>{" "}
          living with{" "}
          <span style={{ fontWeight: 500 }}>humility and honour.</span>
          <br />
          Expressed through good stewardship, accountable relationships and the
          pursuit of justice.{" "}
          <a
            href="https://www.bible.com/bible/116/1PE.3.16.NLT"
            target="_blank"
          >
            1 Peter 3:16
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            COMMITTED TO DISCIPLESHIP
          </span>{" "}
          believing that this is the{" "}
          <span style={{ fontWeight: 500 }}>process of transformation.</span>
          <br />
          Expressed through intentional pursuit of Christlikeness both
          personally and relationally.{" "}
          <a
            href="https://www.bible.com/bible/116/MAT.28.19-20.NLT"
            target="_blank"
          >
            Matthew 28:19-20
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            DEVOTED TO
          </span>{" "}
          <span style={{ fontWeight: 500 }}>
            marriage, family and relationships
          </span>{" "}
          believing that God is relational.
          <br /> Expressed through equipping and supporting people in their
          vital connections with others.{" "}
          <a
            href="https://www.bible.com/bible/116/JHN.15.13.NLT"
            target="_blank"
          >
            John 15:13
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            SERVANT LEADERS
          </span>{" "}
          acknowledging that{" "}
          <span style={{ fontWeight: 500 }}>
            God works through anointed leaders.
          </span>
          <br />
          Expressed through mutual submission and intentionally developing
          leaders.{" "}
          <a
            href="https://www.bible.com/bible/116/MRK.10.42-45.NLT"
            target="_blank"
          >
            Mark 10:42-45
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            RELEVANCE AND ACCURACY
          </span>{" "}
          to our times{" "}
          <span style={{ fontWeight: 500 }}>providing biblical hope</span> for
          present reality and current needs.
          <br /> Expressed through practical support and engagement.{" "}
          <a
            href="https://www.bible.com/bible/116/1CO.9.19-23.NLT"
            target="_blank"
          >
            1 Corinthians 9:19-23
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            GENEROSITY
          </span>{" "}
          <span style={{ fontWeight: 500 }}>
            being a source of life, both physically and spiritually
          </span>
          , by giving from what God has given: talents, abilities, resources,
          time and energy in every season.
          <br /> Expressed through an open heart and open hands.{" "}
          <a
            href="https://www.bible.com/bible/116/2CO.9.6-8.NLT"
            target="_blank"
          >
            2 Corinthians 9:6-8
          </a>
        </Typography>
        <Typography className={classes.scripture}>
          <span style={{ textDecoration: "underline", fontWeight: 500 }}>
            LEGACY
          </span>{" "}
          <span style={{ fontWeight: 500 }}>
            Leaving a godly influence for future generations
          </span>
          , by obeying the call to make disciples.
          <br /> Expressed through engaging with society and influencing it
          positively.{" "}
          <a
            href="https://www.bible.com/bible/116/PSA.78.4.NLT"
            target="_blank"
          >
            Psalm 78:4
          </a>
          ,{" "}
          <a
            href="https://www.bible.com/bible/116/MAT.28.19.NLT"
            target="_blank"
          >
            Matthew 28:19
          </a>
        </Typography>
      </div>
    </Container>
  );
};

export default Values;
