import { Container, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import useStyles from "./Mission.styles";

import { FaCompass, FaCross, FaDove, FaHandsHelping } from "react-icons/fa";
import YoutubeVideo from "../../Shared/YoutubeVideo/YoutubeVideo";

const Mission = () => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#ff9c06", marginTop: 64 }}>
      <Container style={{ padding: "32px 8px" }}>
        <Typography
          align="center"
          variant="h4"
          style={{ color: "#353b48", marginBottom: 32 }}
        >
          Our mission is to help people to:
        </Typography>
        <Grid container>
          <Grid
            alignItems="center"
            justify="center"
            xs={12}
            sm={6}
            style={{ display: "flex" }}
            item
          >
            <ul className={classes.mission}>
              <li>
                <FaCross /> Know God
              </li>
              <li>
                <FaDove /> Find Freedom
              </li>
              <li>
                <FaCompass /> Discover Your Purpose
              </li>
              <li>
                <FaHandsHelping /> Make a Difference
              </li>
            </ul>
          </Grid>
          <Grid xs={12} sm={6} item>
            <YoutubeVideo videoUrl="https://www.youtube.com/embed/m7Cly4G7fc0" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mission;
