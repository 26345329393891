import { Container, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import useStyles from "./StatementOfFaith.styles";

const StatementOfFaith = () => {
  const classes = useStyles();

  return (
    <Container style={{ padding: "32px 8px 64px 8px" }}>
      <Typography variant="h4" style={{ color: "#353b48", marginBottom: 32 }}>
        Statement Of Faith
      </Typography>
      <ol>
        <li className={classes.statement}>
          <Typography>
            We believe in the scriptures of the Old and New Testaments in their
            original writing as fully inspired of God and accept them as the
            supreme and final authority for faith and life.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in one God, eternally existing in three persons- Father,
            Son and Holy Spirit.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that Jesus Christ was begotten of the Father, conceived
            by the Holy Spirit, born of the Virgin Mary and is true God and true
            man.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that God created man in His own image; that man sinned
            and thereby incurred the penalty of sin which is death, physical and
            spiritual; that all human beings inherit a sinful nature which
            issues (in the case of those who reach moral responsibility) in
            actual transgression involving personal guilt.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in the principle of heterosexual relationships between a
            natural man and a natural woman within the confines of lawful
            matrimony.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that the Lord Jesus Christ died for our sins a
            substitutionary sacrifice according to the Scriptures and that all
            who believe in Him are justified on the grounds of His shed blood.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in the bodily resurrection of the Lord Jesus, His
            ascension into Heaven, and His present life as our High Priest and
            Advocate.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in the personal return of the Lord Jesus Christ.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that, all who repent of their sin and receive the Lord
            Jesus Christ by faith are born again of the Holy Spirit and thereby
            become children of God.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in the baptism in the Holy Spirit, empowering and
            equipping believers for service, with the accompanying supernatural
            gifts of the Holy Spirit; and in the fellowship with the Holy
            Spirit. We believe in the divinely ordained ministries of Apostle,
            Prophet, Evangelist, Pastor and Teacher.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe in the resurrection of both the just and the unjust, the
            eternal blessedness of the redeemed and the eternal banishment of
            those who have rejected the offer of salvation.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that the Elders carry the responsibility for oversight
            and care of the staff and church members. Where necessary Elders
            must carry out biblical discipline for the protection of the local
            Church.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that the one true Church is the whole company of those
            who have been redeemed by Jesus Christ and regenerated by the Holy
            Spirit; that the local Church on earth should take its character
            from this and that the new birth and personal confession of Christ
            are essentials of Church membership.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that the Lord Jesus Christ appointed two ordinances-
            Baptism in water and the Lords Supper- to be observed as acts of
            obedience and as perpetual witnesses to the cardinal facts of the
            Christian faith; that Baptism is the immersion of the believer in
            water as a confession of identification with Christ in burial and
            resurrection and that the Lords Supper is the partaking of the
            emblems symbolic of the Saviours broken body and shed blood, in the
            remembrance of His sacrificial death, till He comes.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that divine healing was provided for in the Old Testament
            and is an integral part of the gospel.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            We believe that the Bible teaches that without holiness no man can
            see the Lord. We believe in the doctrine of sanctification as
            definite, yet progressive work of grace, commencing at the time of
            the new birth and continuing until the consummation of salvation.
          </Typography>
        </li>
        <li className={classes.statement}>
          <Typography>
            The Church is open to any further truth, which the Holy Spirit may
            illuminate from the Scriptures.
          </Typography>
        </li>
      </ol>
    </Container>
  );
};

export default StatementOfFaith;
