import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    statement: {
      letterSpacing: 1,
      '&:not(:last-child)': {
          marginBottom: theme.spacing(2),
      }
    }
  })
});

export default useStyles;