import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    scripture: {
        '&:not(:last-child)': {
          paddingBottom: theme.spacing(),
        }
    }
  })
});

export default useStyles;