import * as React from "react";
import Layout from "../layouts/Layout";

import { Mission, Values, StatementOfFaith } from "../components/MissionValues";

const MissionValues = () => {
  return (
    <Layout title="Mission & Values">
      <Mission />
      <Values />
      <StatementOfFaith />
    </Layout>
  );
};

export default MissionValues;
