import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    mission: {
        listStyle: 'none',
        "& li": {
          ...theme.typography.body1,
          padding: theme.spacing(),
          color: '#2d3436',
          display: 'flex',
          alignContent: 'center',
          '& svg': {
            fontSize: 24,
            marginRight: theme.spacing(2)
          }
        },
        "& li:not(:last-child)": {
          marginRight: 48,
        },
        
    }
  })
});

export default useStyles;